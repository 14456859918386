import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";

export default function ConfirmInvoice(props) {
  const [btnStatus, setBtnStatus] = useState(
    props && props.apiResponse === true ? true : false
  );
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    await setBtnStatus(true);
    props.onSubmit(data);
  };
  useEffect(() => {
    const fields = ["address", "email_address"];
    fields.forEach((field) => setValue(field, props.data[field]));
    setValue("first_name", props?.data?.first_name + " " + props?.data?.last_name);
  }, []);
  return (
    <div>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="register-popup"
        onHide={props.closePayment}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {" "}
            <h3>Invoice Details Confirmation</h3>{" "}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div>
                <div className="form-group">
                  <label className="form-label" htmlFor="first_name">
                    Name <span className="requiredColor">*</span>
                  </label>
                  <input
                    {...register("first_name", {
                      required: true,
                    })}
                    autoComplete="new-password"
                    className="form-control"
                  />
                  <span className="error_validation ml-3">
                    {errors.first_name?.type === "required" &&
                      "This field is required."}
                  </span>
                </div>
                <div className="form-group">
                  <label className="form-label" htmlFor="email_address">
                    Email Address <span className="requiredColor">*</span>
                  </label>
                  <input
                    {...register("email_address", {
                      required: true,
                      pattern: {
                        value:
                          /^([\w.-]+)@(\[(\d{1,3}\.){3}|(?!test|testing)(([a-zA-Z\d-]+\.)+))([a-zA-Z]{2,4}|\d{1,3})(\]?)$/,
                        message: "Please enter a valid email",
                      },
                    })}
                    className="form-control"
                    autoComplete="new-password"
                  />
                  <span className="error_validation ml-3">
                    {errors.email_address?.type === "required" &&
                      "This field is required."}
                    {errors.email_address?.message}
                  </span>
                </div>
                <div className="form-group">
                  <label className="form-label" htmlFor="address">
                    Address <span className="requiredColor">*</span>
                  </label>
                  <textarea
                    {...register("address", {
                      required: true,
                    })}
                    autoComplete="new-password"
                    className="form-control"
                  />
                  <span className="error_validation ml-3">
                    {errors.address?.type === "required" &&
                      "This field is required."}
                  </span>
                </div>
              </div>
              <div className="cent-wrapper cent-wrapper-payment">
                <input
                  type="submit"
                  className="btn btn-light"
                  value="Cancel"
                  onClick={props.closePayment}
                />{" "}
                &nbsp;&nbsp;
                <input
                  type="submit"
                  className="btn btn-dark"
                  value="Submit"
                  disabled={btnStatus}
                />
              </div>
            </form>
          </>
        </Modal.Body>
      </Modal>
    </div>
  );
}
