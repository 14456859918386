import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";

import phonesmallicon from "../../../assets/images/successfulcards/phone-small-icon.png";

import mapsmallicon from "../../../assets/images/successfulcards/map-small-icon.png";

import envelope from "../../../assets/images/successfulcards/envelope-fill.svg";

import deletebin from "../../../assets/images/myregisteration/trash-fill.svg";

import Breadcrumbsbanner from "../breadcumbbanner/breadcumbsbanner";
import KanhaRunService from "../../services/kanha.service";
import { navigate } from "gatsby";
import { Button, Modal } from "react-bootstrap";
import backn from "../../../assets/images/backIconSummary.svg";
// import logOutimg from "../../../assets/images/logOut.png";
import ConfirmInvoice from "../confirmInvoice";
import { CreateImageFromInitials, GetRandomColor } from "../../utils/common";
import { lStorage } from "../../utils/storage";
import Loading from "../loading";
import moment from "moment";

export default function SuccesfulCards() {
  const [raceList, setRaceList] = useState([]);
  const pList = lStorage.get("list");
  const kanhaApi = new KanhaRunService();
  const [formData, setFormData] = useState(pList);
  const [paymentPage, setPaymentPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonEnable, setButtonEnable] = useState("");
  const deleteData = async (data) => {
    let removeData = formData && formData.filter((e) => e !== data);
    lStorage.set("list", removeData);
    const parseList = removeData;
    await setFormData(parseList);
  };
  const selectRunType = lStorage.get("selectedLocation");
  const getraceList = () => {
    kanhaApi
      .getracecategory()
      .then((response) => {
        if (
          response.data.isError === false ||
          response.data.statusCode === "200"
        ) {
          if (response.data.isError === false) {
            setRaceList(response.data.data);
            const pList = lStorage.get("list");
            if (pList && pList.length === 0) {
              navigate("/myregisterationlist");
            }
          } else {
            // console.log("error");
            const pList = lStorage.get("list");
            if (pList && pList.length === 0) {
              navigate("/myregisterationlist");
            }
          }
          //  navigate("/basicformpage");
        }
      })
      .catch(function (error) {
        // console.log(error);
        logOut();
        const pList = lStorage.get("list");
        if (
          (pList && pList === null) ||
          (pList && pList === undefined) ||
          (pList && pList.length === 0)
        ) {
          navigate("/myregisterationlist");
        }
      });
  };

  const logOut = () => {
    lStorage.remove("userInfo");
    lStorage.remove("verification_id");
    lStorage.remove("list");
    lStorage.remove("globalRun");
    lStorage.remove("appliedCouponCode");
    navigate("/");
  };

  const backToParticipant = () => {
    navigate("/myregisterationlist");
  };
  const backToRegister = () => {
    navigate("/basicformpage");
  };
  const paymentCheckout = () => {
    navigate("/paymentcheckout");
  };
  const confirmPay = () => {
    setPaymentPage(true);
  };
  const closePayment = () => {
    setPaymentPage(false);
  };
  const proceedToPay = async (data) => {
    if (data.first_name) {
      const payLoad = await {
        invoicedetails: [
          {
            invoice_name: data.first_name,

            invoice_emailid: data.email_address,

            invoice_address: data.address,
          },
        ],
        participants: pList,
      };
      setLoading(true);
      setPaymentPage(false);
      setLoading(true);
      await kanhaApi
        .addparticipant(payLoad)
        .then((response) => {
          if (
            response.data.isError === false ||
            response.data.statusCode === "200"
          ) {
            if (response.data.isError === false) {
              lStorage.remove("list");
              lStorage.remove("appliedCouponCode");
              window.open(response.data.message.paymentUrl, "_parent");
              setLoading(false);
            } else {
              setLoading(false);
              // console.log("error");
            }
          } else {
            if (response.data.isError === true) {
              setButtonEnable("error");
              setLoading(false);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          setLoading(false);
          logOut();
        });
    }
  };

  const initialValue = 0;
  const total =
    pList &&
    pList.reduce(
      (accumulator, current) => accumulator + current.race_price,
      initialValue
    );

  useEffect(() => {
    getraceList();
    const pList = lStorage.get("list");
    if (pList === undefined || (pList && pList.length === 0)) {
      navigate("/myregisterationlist");
    }
  }, [formData]);

  const getOverseasPriceCal = (price) => {
    const unitPrice = 12;
    const calculatedPrice = (price / 1000) * unitPrice;
    return `${calculatedPrice} USD`;
  };

 

  return (
    <>
      <Breadcrumbsbanner />
      <div className="succesfulcards-wrapper inner-pages p-4">
        <div className="container-fluid" style={{ marginBottom: "120px" }}>
          <div className="row">
            <div className="p-2">
              {/* <span onClick={backToParticipant} className="hand">
                <img className="img-fluid" src={backn} alt="back" />
              </span> */}
              <Button
                className="btn btn-primary"
                // style={{ margin: "0 12px" }}
                onClick={() => navigate("/myregisterationlist")}
              >
                {/* <span onClick={backToParticipant} className="hand"> */}
                <img className="img-fluid" src={backn} alt="back" />
                {/* </span>{" "} */}
                Back to My Registration
              </Button>
              {/* <Button
                className="btn btn-primary btn-block"
                style={{ float: "right" }}
                onClick={logOut}
              >
                Logout{" "}
                <img
                  className="img-fluid"
                  src={logOutimg}
                  width="15px"
                  alt="Clock logo"
                />
              </Button> */}
            </div>
            <div className="col-md-9">
              <div className="succesfulcards-wrapper-box">
                <div className="pnrnumber-details">
                  <b>Registration Summary</b>&nbsp;
                  <span> ( Participants Count {pList && pList.length})</span>
                </div>

                {/* <div>
                  <span> Total Amount </span>
                  <span>
                    <b> {total} ₹ </b>
                  </span>
                </div> */}
              </div>
            </div>
          </div>
          <div style={{ padding: "20px 0px 0px 0px" }}>
            <div className="col-md-3 col-sm-6" style={{ display: "inline" }}>
              <Button
                className="btn btn-dark btn-top-mobile10"
                onClick={backToRegister}
                style={{ marginTop: "10px" }}
                disabled={pList?.length >= 5 ? true : false}
              >
                Add More Participant
              </Button>
              &nbsp;&nbsp;
              <Button
                className="btn btn-orange"
                id="success-orange"
                onClick={paymentCheckout}
                style={{ marginTop: "10px" }}
              >
                Proceed to Pay
              </Button>
            </div>
          </div>
          <div className="row">
            {formData &&
              formData.map((data, index) => {
                return (
                  <div className="col-md-3 col-sm-6" key={index + 1}>
                    <div className="card succesful-cards-wrapper first-upper">
                      <Card>
                        <Card.Body>
                          <div
                            className="name-wrapper-item"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <span>
                                <img
                                  id="preview"
                                  width="35px"
                                  src={CreateImageFromInitials(
                                    500,
                                    data.first_name,
                                    GetRandomColor()
                                  )}
                                  alt=""
                                />
                              </span>
                            </div>
                            <div>
                              <div
                                className="deleteiocn hand"
                                onClick={() => {
                                  navigate(`/basicformpage`);
                                  lStorage.set("editSecretId", data.secretId);
                                }}
                                style={{
                                  marginRight: "55px",
                                }}
                                title="Edit"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="15"
                                  fill="currentColor"
                                  class="bi bi-pen"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z" />
                                </svg>
                              </div>{" "}
                              <div>
                                <h5
                                  className="deleteiocn hand"
                                  onClick={() => {
                                    deleteData(data);
                                  }}
                                  style={{
                                    paddingTop: "3px",
                                  }}
                                  title="Delete"
                                >
                                  <img
                                    className="img-fluid"
                                    src={deletebin}
                                    alt="Clock logo"
                                  />
                                </h5>
                              </div>
                            </div>
                          </div>
                          <ul style={{ paddingTop: "10px" }}>
                            <li>
                              <b>Name</b>
                            </li>
                            <li>
                              {data.first_name} {data.last_name}
                            </li>
                            <li>
                              <b>Gender</b>
                            </li>
                            <li>{data.gender}</li>
                            {data?.blood_group != "" && (
                              <>
                                {data?.race_km?.includes("virtual-run") ===
                                  false && (
                                  <>
                                    <li>
                                      <b>Blood group </b>
                                    </li>
                                    <li>{data.blood_group}</li>
                                  </>
                                )}
                              </>
                            )}
                            {data?.dob != "" && (
                              <li>
                                <b>DOB</b>
                              </li>
                            )}
                            {data?.dob != "" && (
                              <li>
                                {moment(data && data.dob).format("DD-MM-YYYY")}
                              </li>
                            )}
                            {data?.dob == "" && data?.age != "" && (
                              <li>
                                <b>Age</b>
                              </li>
                            )}
                            {data?.dob == "" && data?.age != "" && (
                              <li>{data.age}</li>
                            )}
                            {/* <li>
                              <b>BIB Name</b>
                            </li>
                            <li>{data.name_on_bib}</li> */}
                            {data?.t_shirt_size != "" && (
                              <li>
                                <b>T-shirt Size</b>
                              </li>
                            )}
                            {data?.t_shirt_size != "" && (
                              <li>{data.t_shirt_size}</li>
                            )}
                          </ul>

                          <div>
                            <ul className="contact-wrapper-box">
                              <li>
                                <span>
                                  <img
                                    className="img-fluid"
                                    src={phonesmallicon}
                                    alt="Clock logo"
                                  />
                                </span>
                                {data.contact_number}
                              </li>
                              <li style={{ textTransform: "none" }}>
                                <span>
                                  <img
                                    className="img-fluid"
                                    src={envelope}
                                    alt="Clock logo"
                                  />
                                </span>
                                {data.email_address}
                              </li>
                              {data?.address != "" && (
                              <li>
                                <span>
                                  <img
                                    className="img-fluid"
                                    src={mapsmallicon}
                                    alt="Clock logo"
                                  />
                                </span>
                                {data.address}
                              </li>
                               )}
                              {data?.overnight_accommodation != "" && (
                                <li>
                                  <b>Accommodation</b> :{" "}
                                  {data.overnight_accommodation === "Y"
                                    ? "Yes"
                                    : "No"}
                                </li>
                              )}
                              {data?.bib_collection_location != "" && (
                                <li>
                                  <b>BIB collection location</b> :{" "}
                                  {data.bib_collection_location}
                                </li>
                              )}
                              <li>
                                <b>Race Category</b> : {data.race_category_name}
                              </li>
                              {Number(data.gift_a_tree_count) > 0 && (
                                <li>
                                  <b>Gift A Tree Count</b> :{" "}
                                  {data.gift_a_tree_count}
                                </li>
                              )}
                              {Number(data.gift_a_run) > 0 && (
                                <li>
                                  <b>Gift A Run Count</b> : {data.gift_a_run}
                                </li>
                              )}
                            </ul>
                          </div>
                          <div className="amount-item-wrapper">
                            <h4> ₹ {data?.race_price} 
                            {selectRunType && selectRunType.runType === "Overseas" && (
                               <>{" "}
                              ({getOverseasPriceCal(data?.race_price)})
                              </>
                              )
                            }
                            </h4>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                );
              })}
          </div>
          {/* <div className="col-md-3 col-sm-6" style={{display:"inline"}}>
            <Button className="btn btn-dark" onClick={backToRegister}>
              Add More Participant
            </Button>
            &nbsp;&nbsp;
            <Button
              className="btn btn-orange"
              id="success-orange"
              disabled={pList && pList.length === 0 ? true : false}
              onClick={confirmPay}
            >
              Proceed to Pay
            </Button>
          </div> */}
        </div>
      </div>
      {paymentPage && paymentPage === true && (
        <ConfirmInvoice
          show={paymentPage}
          data={pList && pList[0]}
          apiResponse={buttonEnable}
          closePayment={closePayment}
          onSubmit={proceedToPay}
        />
      )}
      {loading === true && (
        <Modal id="loading" show={loading}>
          <Loading />
        </Modal>
      )}
    </>
  );
}
